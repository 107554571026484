import store from "@/store";

let formatSecondsToDate = function (seconds) {
  return formatDate(new Date(seconds * 1000));
}

let formatDate = function (date) {
  let currentMeeting = store.getters.currentMeeting;
  const timeZone = currentMeeting ? currentMeeting.TimeZone : undefined;

  let dateOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  if (timeZone) {
    dateOptions.timeZone = timeZone;
  }

  return date.toLocaleString("en-US", dateOptions);
};

let formatDateWithTime = function (date) {
  let currentMeeting = store.getters.currentMeeting;
  const timeZone = currentMeeting ? currentMeeting.TimeZone : undefined;

  let dateOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };

  if (timeZone) {
    dateOptions.timeZone = timeZone;
  }

  return date.toLocaleString("en-US", dateOptions);
};

let formatTime = function (date) {
  let currentMeeting = store.getters.currentMeeting;
  const timeZone = currentMeeting ? currentMeeting.TimeZone : undefined;

  let dateOptions = {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };

  if (timeZone) {
    dateOptions.timeZone = timeZone;
  }

  return date.toLocaleString("en-US", dateOptions);
};

export { formatDate, formatDateWithTime, formatTime, formatSecondsToDate };
