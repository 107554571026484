<template>
  <div>
    <router-link :to="{ name: to }" class="">
      <aad-icon class="back-caret" name="caret-left"></aad-icon>
      {{ text }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: ["text", "to"],
};
</script>
