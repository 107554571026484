const baseConfig = {
  MeetingCode: "SM2021",
  MeetingName: "2021 Summer Meeting",
  Sponsor: {
    IsActive: false,
    Url: "https://castlebiosciences.com/",
    // Need to use require on image urls for webpack to pick up in compile time
    ImageUrl: require("@/assets/sponsorSM2021.png"),
    ImageAltText: "Sponsor Image",
    BoothNumber: "715",
  },
};

export default baseConfig;
