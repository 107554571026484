<template>
  <div>
    <p class="sidebar-navigation-item">
      <img
        v-if="route.IsLocalImage"
        class="sidebar-item-icon rounded-circle"
        :src="require('@/assets/' + route.IconURL)"
        :alt="route.Name + ' icon'"
      />
      <img
        v-else
        class="sidebar-item-icon"
        :src="route.IconURL"
        :alt="route.Name + ' icon'"
      />

      <a
        :href="route.DestinationURL"
        v-if="route.SectionType === 'Url'"
        target="_blank"
        @click="externalLinkClick(route.DestinationURL)"
      >
        {{ route.Name }}
      </a>

      <router-link
        v-else-if="route.LinkedPageName == 'Login' && user"
        :to="{name: 'Login', query: { redirect: '/', name: 'Logout' }}"
        @click.native="logout"
        class="sidebar-navigation-item-link"
        >Logout</router-link
      >

      <router-link
        v-else
        :to="{ name: route.LinkedPageName || 'NotFound' }"
        class="sidebar-navigation-item-link"
      >
        <span v-if="route.LinkedPageName != 'Login'">{{ route.Name }}</span>

        <!-- Custom Logic for switch Login/Logout -->
        <span v-else>Login</span>
      </router-link>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["route"],
  methods: {
    externalLinkClick: function (url) {
      this.$analytics.logEvent("external_link", { destination: url });
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
.sidebar-navigation-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dee3ec;
  padding: 5px 0;
  margin: 0;
}

.sidebar-item-icon {
  height: 25px;

  width: 25px;
  margin: 5px;
}
</style>
